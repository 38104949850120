import { graphql } from "gatsby"
import React from "react"
import Testimonial from "../components/home/testimonial"
import Layout from "../components/layout"
import Header from "../components/section/header"
import Seo from "../components/seo"

export default function TestimonialPage({ data, location }) {
  // console.log(data)
  return (
    <Layout>
      <Seo title="Testimonial" pathname={location.pathname} />
      <Header title="Testimonials" />
      <section>
        <Testimonial hide={true} testimonials={data.testimonials.nodes} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    testimonials: allMarkdownRemark(
      filter: {
        frontmatter: { publish: { eq: true } }
        fields: { collection: { eq: "testimonials" } }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          content
          country

          position
        }
      }
    }
  }
`
